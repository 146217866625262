/**
 *  - recipesFactory
 *  - RecipeController
 *  - AsideController
 */

(function () {
  'use strict';

  angular
    .module('mainApp')
    .factory('recipeNotesFactory', [
      '$http',
      'appConfig',
      function ($http, appConfig) {
        const _notesFactory = {};

        _notesFactory.getNotes = function (niceName) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'GET',
              url: appConfig.mycookTouchDomain + '/recipeNotes/' + niceName,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _notesFactory.saveNote = function (niceName, body) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'POST',
              url: appConfig.mycookTouchDomain + '/recipeNotes/' + niceName,
              data: body,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        _notesFactory.deleteNote = function (niceName, id) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'DELETE',
              url:
                appConfig.mycookTouchDomain +
                '/recipeNotes/' +
                niceName +
                '/' +
                id,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        return _notesFactory;
      },
    ])
    .factory('nutritionalFactory', [
      '$http',
      'appConfig',
      function ($http, appConfig) {
        const _nutriFactory = {};

        _nutriFactory.getErrors = function (niceName) {
          return new Promise(function (resolve, reject) {
            $http({
              method: 'GET',
              url:
                appConfig.mycookTouchDomain + '/nutritionalErrors/' + niceName,
            }).then(
              function (res) {
                resolve(res.data.response);
              },
              function (err) {
                reject(err);
              }
            );
          });
        };

        return _nutriFactory;
      },
    ])
    .controller('RecipeController', [
      '$scope',
      '$rootScope',
      '$routeParams',
      '$route',
      '$location',
      '$window',
      '$filter',
      '$log',
      '$cookies',
      'saRouter',
      '_',
      'toolsService',
      'appConfig',
      'recipesFactory',
      'bannersFactory',
      'usersManager',
      'ngDialog',
      'mycookTouchWebSocketFactory',
      'serviceRanks',
      'unitsService',
      'serviceLandings',
      'webStorage',
      '$timeout',
      'recipeNotesFactory',
      'followersService',
      'nutritionalFactory',
      function (
        $scope,
        $rootScope,
        $routeParams,
        $route,
        $location,
        $window,
        $filter,
        $log,
        $cookies,
        saRouter,
        _,
        toolsService,
        appConfig,
        recipesFactory,
        bannersFactory,
        usersManager,
        ngDialog,
        mycookTouchWebSocketFactory,
        serviceRanks,
        unitsService,
        serviceLandings,
        webStorage,
        $timeout,
        recipeNotesFactory,
        followersService,
        nutritionalFactory
      ) {
        $scope.temperatureUnit  = localStorage.getItem('temperatureUnit');
        $scope.weightUnit = localStorage.getItem('weightUnit');
        var toUnregister = [];

        $scope.ts = new Date().getTime().toString();

        $scope.allowedCompats = appConfig.allowedCompats || ['S1'];

        $scope.region = appConfig.region;
        $scope.enableComments = appConfig.enableComments;
        console.log('ENABLE COMMENTS:', $scope.enableComments, appConfig);
        $scope.today = $filter('date')(new Date(), 'yyyy-MM-dd hh:mm:ss');

        function init() {
          $scope.responseMsg = '';
          $scope.currentDate = new Date();
          $scope.showDeleteDiag = false;
          $scope.machineSelected = webStorage.get('userMachine')
            ? webStorage.get('userMachine')
            : localStorage.getItem('machineSelected')
              ? localStorage.getItem('machineSelected')
              : '';

          $scope.commentIsValid = function (c) {
            var emtpyComment = {
              date: '',
              comments: [{ date: '' }],
            };

            var _c = angular.copy(c);
            return c && c != null && !angular.equals(c, emtpyComment);
          };

          var idrec = $routeParams.recipeNiceName;

          // comments
          $scope.replies = {};
          $scope.replies.boxes = [];
          $scope.oldStyle = false;

          bannersFactory
            .getBanners(webStorage.get('userNiceName') ? true : false)
            .then(function () {
              $scope.banner_top = bannersFactory.getBanner('recipe top');
              $scope.banner_bottom = bannersFactory.getBanner('recipe bottom');
            });

          // var doNotReload = false;
          // $scope.$on('$locationChangeSuccess', function (event) {
          //   // Want to prevent re-loading when changing from same "cluster recipes"
          //   if (doNotReload) {
          //     $route.current = lastRoute; //Does the actual prevention of routing
          //     doNotReload = false;
          //   }
          // });

          $scope.getCloudVersion = function () {
            var userMachine = webStorage.get('userMachine');

            return userMachine ? parseFloat(userMachine.substring(1)) : 0;
          };

          $scope.recipeView = function (userMachine, oldStyle, recipeName) {
            if ($scope.recipeContent.niceName !== recipeName) {
              $scope.fetchRecipe(recipeName).then(function () {
                localStorage.setItem('machineSelected', userMachine);
                $scope.machineSelected = localStorage.getItem(
                  'machineSelected'
                );
                $scope.oldStyle = oldStyle;

                // $location.path(
                //   $rootScope.url('recipe', { recipeNiceName: recipeName })
                // );
              });
            } else {
              localStorage.setItem('machineSelected', userMachine);
              $scope.machineSelected = localStorage.getItem('machineSelected');
              $scope.oldStyle = oldStyle;
            }
          };

          $scope.fetchRecipe = function (niceName) {
            return recipesFactory.getRecipe(niceName).then(
              function (recipe) {
                $log.debug('RecipeController > getRecipe > SUCCESS: ', recipe);

                // Reverse order comments from new to old
                //recipe.comments = recipe.comments.reverse();
                //recipe.ratings = recipe.ratings.reverse();

                // Get rating stats
                var rating_stats = _.countBy(recipe.ratings, function (str) {
                  return str.rate;
                });

                recipe.rating_stats = rating_stats;
                if (toolsService.isEmpty(recipe.rate)) recipe.rate = 0;

                // default ratings not empty
                if (toolsService.isEmpty(recipe.ratings)) recipe.ratings = [];
                console.log('currentUser: ', $scope.currentUser);
                if ($scope.currentUser && $scope.currentUser.niceName) {
                  var ratingPos = recipe.ratings.findIndex(function (rating) {
                    return rating.niceName === $scope.currentUser.niceName;
                  });

                  if (ratingPos !== -1) {
                    recipe.rating_by_user_position = ratingPos;
                    recipe.rating_by_user = recipe.ratings[ratingPos];
                  }
                }

                if (
                  recipe.seo &&
                  recipe.seo.suggestions &&
                  recipe.seo.suggestions.length > 0
                ) {
                  $scope.suggestedRecipes = recipe.seo.suggestions;
                } else {
                  $scope.suggestedRecipes = null;
                }

                //set robots meta
                var follow = 'follow',
                  index = 'index';

                if (recipe.seo.follow === false) follow = 'nofollow';
                if (recipe.seo.index === false) index = 'noindex';

                var robots = [follow, index];
                robots = robots.join();
                $rootScope.seo_robots = robots;
                //end set robots meta

                var seo_title =
                  !toolsService.isEmpty(recipe.seo) &&
                    !toolsService.isEmpty(recipe.seo.title)
                    ? recipe.seo.title
                    : recipe.title;
                seo_title +=
                  ' ' +
                  $rootScope.translations.seo.separator +
                  ' ' +
                  $rootScope.translations.seo.website_name;
                $rootScope.pageTitle = seo_title;
                $rootScope.pageImage =
                  appConfig.mycookTouchDomain +
                  '/image/recipe/540x391/' +
                  idrec; // fb og image -
                var pgDesc = '';

                if (
                  !toolsService.isEmpty(recipe.seo) &&
                  !toolsService.isEmpty(recipe.seo.description)
                ) {
                  pgDesc = recipe.seo.description;
                } else {
                  pgDesc =
                    $filter('translateVariables')(
                      $rootScope.translations.seo.recipe.description,
                      recipe.title
                    ) +
                    ' ' +
                    recipe.ingredientsSummary.join(', ');
                }

                $rootScope.pageDescription =
                  !toolsService.isEmpty(recipe.seo) &&
                    !toolsService.isEmpty(recipe.seo.description)
                    ? recipe.seo.description
                    : pgDesc;

                if (
                  !toolsService.isEmpty(recipe.seo) &&
                  !toolsService.isEmpty(recipe.seo.canonical)
                ) {
                  $rootScope.canonical = recipe.seo.canonical;
                } else if (recipe.status && recipe.status.idParent) {
                  var browserUrl = $location.path();
                  browserUrl = browserUrl.split('/');
                  var parentUrl =
                    $location.protocol() +
                    '://' +
                    $location.host() +
                    '/' +
                    browserUrl[1] +
                    '/' +
                    recipe.status.idParent;
                  $rootScope.canonical = parentUrl;
                }

                // Set Meta cooktime
                recipe.metaCookTime = 'PT';
                var hours = parseInt(recipe.totalTime / 3600, 10) % 24;
                var minutes = parseInt(recipe.totalTime / 60, 10) % 60;
                if (hours) {
                  recipe.metaCookTime += hours + 'H';
                }
                if (minutes) {
                  recipe.metaCookTime += minutes + 'M';
                }
                if (recipe.totalTime < 60) {
                  recipe.metaCookTime += recipe.totalTime + 'S';
                }

                // Fake step number
                var fakeCounter = 1;
                for (var i = 0; i < recipe.groups.length; i++) {
                  for (var j = 0; j < recipe.groups[i].steps.length; j++) {
                    recipe.groups[i].steps[j].fakeStep = fakeCounter;
                    fakeCounter++;
                  }
                }

                if (recipe.videos.length > 0 && recipe.videos[0] !== '') {

                  recipe.videoUrl = toolsService.getEmbedVideo(recipe.videos[0], {
                    rel: 0,
                    showinfo: 0,
                    autohide: 1,
                    enablejsapi: 1,
                    modestbranding: 1
                  });
                  
                }

                // change rank
                serviceRanks.getRanks().then(function (ranks) {
                  if (recipe.user.rank) {
                    var found = $filter('filter')(
                      ranks,
                      { niceName: recipe.user.rank },
                      true
                    );
                    if (found.length > 0) {
                      recipe.user.rank = found[0].name;
                    }
                  } else {
                    recipe.user.rank = '';
                  }
                });

                // get nutritional
                if (
                  recipe.nutritional &&
                  recipe.status &&
                  (recipe.status.nutritional == 'Auto (Good)' ||
                    recipe.status.nutritional == 'Manual')
                ) {
                  var array = [
                    ['Energy'],
                    ['Lipid Tot', ['FA Sat']],
                    ['Carbohydrt', ['Sugar Tot']],
                    ['Protein'],
                    ['Fiber TD'],
                    /*['Colesterol'],*/['Sodium'] /*,['Water']*/,
                  ];
                  recipe.nutritionalTable = unitsService.getNutritionalTable(
                    recipe.nutritional,
                    array
                  );
                }

                // Custom content - only Spain
                if (appConfig.region == 'ES-MYCOOKTOUCH') {
                  // ebooks
                  var recipeEbooks = [];

                  recipe.accesoriesList.forEach(function (acc) {
                    recipeEbooks.push('accessories/' + acc);
                  });

                  recipe.tags.forEach(function (tag) {
                    recipeEbooks.push(tag);
                  });

                  recipeEbooks.push('category/' + recipe.categoryNiceName);

                  var projection = {
                    img: 1,
                    niceName: 1,
                    title: 1,
                    categories: 1,
                    tags: 1,
                    _id: 0,
                  };

                  serviceLandings
                    .getLandingsByType('ebook', {}, projection) 
                    .then(function (clusterEbooks) {
                      var categoryFound = _.filter(
                        clusterEbooks.results,
                        function (ebook) {
                          var found = _.findIndex(
                            ebook.categories,
                            function (cat) {
                              return recipe.categoryNiceName == cat;
                            }
                          );
                          return found > -1 ? true : false;
                        }
                      );

                      var tagsFound = _.filter(
                        clusterEbooks.results,
                        function (ebook) {
                          var tags = _.intersection(ebook.tags, recipe.tags);
                          return tags.length > 0 ? true : false;
                        }
                      );

                      var ebooksRandom = _.union(categoryFound, tagsFound),
                        selectedEbook = _.sample(ebooksRandom);

                      if (typeof selectedEbook !== 'undefined') {
                        selectedEbook.niceName_noebook = selectedEbook.niceName.replace(
                          'ebook-',
                          ''
                        );
                        $scope.other_content = selectedEbook;
                      }
                    });

                  var diets = {
                    'Dietas/Sin lactosa': 'LowLactoseDiet',
                    'Dietas/Sin gluten': 'GlutenFreeDiet',
                    'Dietas/Baja en calorías': 'LowCalorieDiet',
                    'Dietas/Vegetariana': 'VegetarianDiet',
                  };

                  var tagDietsFound = _.filter(recipe.tags, function (element) {
                    return diets[element];
                  });

                  recipe.suitableForDiet = [];

                  tagDietsFound.forEach(function (el) {
                    recipe.suitableForDiet.push(diets[el]);
                  });

                  // Gastronomía
                  recipe.recipeCuisine = [];
                  recipe.tags.forEach(function (el) {
                    if (_.startsWith(el, 'Gastronomía')) {
                      var type = _.last(_.split(el, '/'));
                      if (recipe.recipeCuisine.indexOf(type) === -1) {
                        recipe.recipeCuisine.push(type);
                      }
                    }
                  });
                }

                // set current PAX
                $scope.selectedPax = recipe.size[recipe.size.current];
                $scope.lengthPax = Object.keys(recipe.size).length - 1;

                // check units of weight and temperature
                if(recipe.groups.length) {
                  var currentweightUnit = localStorage.getItem('weightUnit');
                  var currentTemperatureUnit  = localStorage.getItem('temperatureUnit');
                  if(currentweightUnit || currentTemperatureUnit) {
                    for(var i = 0; i< recipe.groups.length; i++) {
                      if(recipe.groups[i].ingredients) {
                        recipe.groups[i].ingredients.forEach(function(ingredient){
                          if(ingredient.unit== 'gr' && currentweightUnit ){
                            ingredient.unit = currentweightUnit
                            ingredient.qty = $rootScope.converWeight(ingredient.qty,currentweightUnit);
                          }
                        })
                      }
                      if(recipe.groups[i].steps) {
                        recipe.groups[i].steps.forEach(function(step) {
                          if(step.ingredients && step.ingredients.length) {
                            step.ingredients.forEach(function(ingredient){
                              if(ingredient.unit== 'gr' && currentweightUnit){
                                ingredient.unit = currentweightUnit
                                ingredient.qty = $rootScope.converWeight(ingredient.qty,currentweightUnit);
                              }
                            })
                          }
                          if(step.temperature && currentTemperatureUnit == 'F') {
                            step.temperature = $rootScope.cToF(step.temperature);
                          }
                        })
                      }
                    }
                  }
                }
                // if(recipe.nutritional) {
                //   Object.keys(recipe.nutritional).forEach(function(key) {
                //     if(recipe.nutritional[key].unit == 'g') {
                //       recipe.nutritional[key].unit = currentweightUnit
                //       recipe.nutritional[key].value = $rootScope.converWeight(recipe.nutritional[key].value,currentweightUnit);
                //     }
                //   })
                // }
                // if(recipe.nutritionalTable && recipe.nutritionalTable.length) {
                //   recipe.nutritionalTable.forEach(function(table) { 
                //     if(table.quantity && table.quantity.unit == 'g') {
                //       table.quantity.unit = currentweightUnit
                //       table.quantity.value = $rootScope.converWeight(table.quantity.value,currentweightUnit);
                //     }
                //     if(table.child && table.child.length ) {
                //       table.child.forEach(function(child) {
                //         if(child.quantity && child.quantity.unit) {
                //           child.quantity.unit = currentweightUnit
                //           child.quantity.value = $rootScope.converWeight(child.quantity.value,currentweightUnit);
                //         }
                //       })
                //     }
                //   })
                // }


                // check ends

                $scope.recipeContent = recipe;

                var route = saRouter.getRoute('recipe');
                var recipeUrl = saRouter.replaceUrlParams(route.url, {
                  recipeNiceName: recipe.niceName,
                });
                recipeUrl =
                  $location.protocol() + '://' + $location.host() + recipeUrl;
                $scope.currentUrl = recipeUrl;

                $rootScope.$broadcast('UPDATED_RECIPE_DATA', recipe);

                var twMsg = $filter('translateVariables')(
                  $rootScope.translations.single_recipe.share_by_twitter_text,
                  $scope.recipeContent.title
                );
                $scope.pinterestMsg = encodeURIComponent(twMsg);

                twMsg =
                  '?url=' + recipeUrl + '&text=' + encodeURIComponent(twMsg);
                if ($rootScope.translations.config.social_media.twitter !== '')
                  twMsg +=
                    '&via=' +
                    $rootScope.translations.config.social_media.twitter;

                $scope.twMsg = twMsg;

                recipeNotesFactory
                  .getNotes($routeParams.recipeNiceName)
                  .then(function (notes) {
                    $scope.stepNotes = notes ? notes.steps : [];
                  });

                if (!$scope.machineSelected) {
                  $scope.machineSelected = $scope.recipeContent.designedTo;
                } else {
                  var compat = $scope.machineSelected.includes('.')
                    ? $scope.machineSelected.split('.')[0]
                    : $scope.machineSelected;

                  if (
                    !(
                      $scope.recipeContent.compatibility.current &&
                      $scope.recipeContent.compatibility.current.includes(
                        compat
                      )
                    )
                  ) {
                    $scope.machineSelected = $scope.recipeContent.designedTo;
                  }
                }
              },
              function (error) {
                $log.error('RecipeController > getRecipe >  ERROR2: ', error);
                $window.location.href = '404';
              }
            );
          };

          $scope.fetchRecipe($filter('urlEncode')(idrec));

          $scope.getStepFunction = function (step) {
            var fnc;
            if (step.type === 'mycook') {
              if (step.function) fnc = step.function;
              else {
                if (/^([1-9]|[1-9][0-1])T$/i.test(step.speed)) fnc = 'turbo';
                if (/(.*)\-(.*)/.test(step.speed)) fnc = 'progressive';
                if (['S', 'S1', 'S2'].includes(step.speed)) fnc = 'saute';
                if (step.speed === 'A') fnc = 'knead';
              }
            } else fnc = step.type;

            return fnc || null;
          };

          $scope.getStepImageWidth = function (grpIdx, stepIdx) {
            if (screen.width <= 640) {
              var elem = $('#step-image-container-' + grpIdx + '-' + stepIdx);

              return elem && elem[0] ? elem[0].offsetWidth : 0;
            } else return 185;
          };

          $scope.toNewTouch = function (recipeNiceName) {
            $location
              .path(
                $rootScope.url('editRecipe', { recipeNiceName: recipeNiceName })
              )
              .search('case', 'adapt');
          };

          $scope.recipeEdit = function (recipeNiceName) {
            //url('editRecipe', {recipeNiceName: recipeContent.niceName})
            $location
              .path(
                $rootScope.url('editRecipe', { recipeNiceName: recipeNiceName })
              )
              .search('case', 'edit');
          };

          $scope.createNewSize = function (recipeNiceName) {
            //url('editRecipe', {recipeNiceName: recipeContent.niceName})
            $location
              .path(
                $rootScope.url('editRecipe', { recipeNiceName: recipeNiceName })
              )
              .search('case', 'size');
          };

          $scope.canAdapt = function () {
            //adminRoles.indexOf(currentUser.role) > -1 && (recipeContent.compatibility.S1 !== undefined && recipeContent.compatibility.S2 !== undefined) && recipeContent.compatibility.S1 !== recipeContent.compatibility.S2
            if ($scope.currentUser && $scope.recipeContent) {
              var supported = Object.keys(
                $scope.recipeContent.compatibility
              ).filter(function (k) {
                return k != 'current';
              });
              return (
                $scope.adminRoles.includes($scope.currentUser.role) &&
                supported.length <
                2 /* Este dos deberia ser una variable global */ &&
                $scope.allowedCompats.length > 1
              );
            } else return false;
          };

          $scope.canSize = function () {
            if ($scope.currentUser && $scope.recipeContent) {
              return (
                $scope.adminRoles.includes($scope.currentUser.role) ||
                $scope.recipeContent.status.mine
              );
            } else return false;
          };

          $scope.amplifyImage = function (childId, title, subtitle, idxs) {
            var childIdx;

            if (idxs) {
              childIdx =
                $scope.recipeContent.groups.reduce(function (
                  acum,
                  group,
                  grIdx
                ) {
                  return grIdx < idxs.group ? acum + group.steps.length : acum;
                },
                  0) + idxs.step;
            }

            $scope.amplifiedImage = {
              paths: {
                main:
                  $scope.mycookTouchDomain +
                  '/image/recipe/' +
                  $scope.recipeContent.niceName +
                  '/1920x/' +
                  childId,
                mobile:
                  $scope.mycookTouchDomain +
                  '/image/recipe/' +
                  $scope.recipeContent.niceName +
                  '/1000x/' +
                  childId,
                fallback:
                  $scope.mycookTouchDomain +
                  '/image/recipe/' +
                  $scope.recipeContent.niceName +
                  '/750x/' +
                  childId,
              },
              title: title,
              subtitle: childIdx ? subtitle + ' ' + childIdx : subtitle,
            };
          };

          $scope.openDialog = function (
            element,
            nutritionalTable,
            templateID,
            className
          ) {
            $scope.recipe_item = element;
            $scope.nutritionalTable = nutritionalTable;

            ngDialog.open({
              template: templateID,
              className: className,
              scope: $scope,
            });
          };

          // Get Price - Difficulty recipe text
          $scope.currentPriceDifficultyTitle = function (current, type) {
            switch (current) {
              case 1:
                current = 'low';
                break;
              case 2:
                current = 'medium';
                break;
              case 3:
                current = 'high';
                break;
            }
            return $rootScope.translations.common[type + '_' + current];
          };

          $scope.filterDate = function (date) {
            return $filter('date')(date, 'dd-MM-yyyy');
          };

          $scope.showRecipeWarning = function () {
            return (
              $scope.recipeContent.designedTo === 'mycooktouch' &&
              $scope.oldStyle === true
            );
          };

          // DELETE RECIPE
          $scope.showDeleteDialog = function (recipe) {
            $scope.machine = '';

            if (recipe.compatibility.current == 'S1') {
              $scope.machine = 'Mycook estandar/Mycook Touch';
              console.log($scope.machine);
            } else if (recipe.compatibility.current == 'S2') {
              $scope.machine = 'Mycook Touch new edition';
              console.log($scope.machine);
            } else if ((recipe.compatibility.current == 'S1', 'S2')) {
              $scope.machine = 'Mycook/Mycook Touch new edition';
              console.log($scope.machine);
            }

            if (!toolsService.isEmpty(recipe.niceName)) {
              $scope.recipeToDelete = {
                niceName: recipe.niceName,
                title: recipe.title,
                machine: $scope.machine,
              };

              ngDialog
                .openConfirm({
                  template: 'remove-recipe.html',
                  className: 'new-recipe',
                  scope: $scope,
                })
                .then(
                  function (r) {
                    recipesFactory
                      .deleteRecipe($scope.recipeToDelete.niceName)
                      .then(
                        function (res) {
                          $location.path($rootScope.url('recipeCategories'));
                        },
                        function (err) {
                          $log.error(
                            'CategoryController > deleteMyRecipe ERROR: ',
                            err
                          );
                        }
                      );
                  },
                  function (e) { }
                );
            }
          };

          $scope.showPublishDialog = function (recipe, title) {
            if (!toolsService.isEmpty(recipe.niceName)) {
              $scope.recipeToPublish = {
                niceName: recipe.niceName,
                title: title,
              };

              ngDialog
                .openConfirm({
                  template: 'send-recipe.html',
                  className: 'publish-recipe',
                  scope: $scope,
                })
                .then(
                  function (r) {
                    //quitar los tags de draft
                    recipe.grants.view = ['public'];

                    recipe.grants.search = recipe.grants.search.filter(
                      function (g) {
                        return !g.startsWith('draft/');
                      }
                    );

                    recipe.grants.search.push('public');

                    recipe.info = {
                      creationDate: new Date(),
                      creationSource: $scope.deviceDetector.raw.userAgent,
                    };

                    recipesFactory.edit(recipe).then(
                      function (res) {
                        $rootScope.$broadcast('updateDraftRecipes', {});
                        $route.reload();
                      },
                      function (err) {
                        $log.error(
                          'CategoryController > publishMyRecipe ERROR: ',
                          err
                        );
                      }
                    );
                  },
                  function (e) { }
                );
            }
          };

          // FAVORITES
          $scope.markAsFavorite = function (userId, recipeId, event) {
            event.preventDefault(); // sino me vuelve a la pag. principal
            recipesFactory
              .markAsFavorite(userId, recipeId)
              .success(function (favoriteRes) {
                $scope.favoriteResult = 'added';
                if (!$scope.recipeContent.social) {
                  $scope.recipeContent.social = {};
                }

                if (!$scope.recipeContent.social.favorite) {
                  $scope.recipeContent.social.favorite = 0;
                }

                $scope.recipeContent.social.favorite += 1;
                $scope.currentUser.favorites.push(recipeId);
              })
              .error(function (error) {
                $log.error('ERROR markAsFavorite: ', error);
              });
          };

          $scope.removeFromFavorite = function (userId, recipeId, event) {
            event.preventDefault();
            recipesFactory
              .removeFromFavorite(userId, recipeId)
              .success(function (favoriteRes) {
                $scope.favoriteResult = 'removed';
                $scope.recipeContent.social.favorite -= 1;
                $scope.currentUser.favorites.pop(recipeId);
              })
              .error(function (error) {
                $log.error('ERROR removeFromFavorite: ', error);
              });
          };

          // ToDo recipes
          $scope.markAsToDo = function (userId, recipeId, event) {
            event.preventDefault(); // sino me vuelve a la pag. principal
            recipesFactory
              .markAsToDo(userId, recipeId)
              .success(function (todoRes) {
                $scope.todoResult = 'added';
                if (!$scope.recipeContent.social) {
                  $scope.recipeContent.social = {};
                }

                if (!$scope.recipeContent.social.todo) {
                  $scope.recipeContent.social.todo = 0;
                }

                $scope.recipeContent.social.todo += 1;
                $scope.currentUser.todo.push(recipeId);
              })
              .error(function (error) {
                $log.error('ERROR markAsToDo: ', error);
              });
          };

          $scope.removeFromToDo = function (userId, recipeId, event) {
            event.preventDefault();
            recipesFactory
              .removeFromToDo(userId, recipeId)
              .success(function (todoRes) {
                $scope.todoResult = 'removed';
                $scope.recipeContent.social.todo -= 1;
                $scope.currentUser.todo.pop(recipeId);
              })
              .error(function (error) {
                $log.error('ERROR removeFromTodo: ', error);
              });
          };

          $scope.sendToMycookTouch = function () {
            $log.debug('sendToMycookTouch', $scope.recipeContent);
            mycookTouchWebSocketFactory.sendRecipe(
              $scope.recipeContent.niceName,
              $scope.recipeContent.title,
              $scope.currentUser.niceName
            );
          };

          $scope.changePaxRecipe = function () {
            var url = $rootScope.url('recipe', {
              recipeNiceName: $scope.selectedPax,
            });
            $location.path(url);
          };

          $scope.addIngredient = function (ingredient) {
            recipesFactory.addIngredient(ingredient);
          };

          $scope.addAllIngredients = function (recipeName) {
            recipesFactory.addAllIngredients(recipeName);
          };

          $scope.showDietPlanDialog = function (recipe) {
            $scope.recipeToDietPlan = recipe;
            $scope.showDietPlan = true;
          };

          $scope.closeDietPlanDialog = function () {
            $scope.recipeToDietPlan = null;
            $scope.showDietPlan = false;
          };

          $scope.adding = {};
          $scope.editing = {};

          $scope.cancelNote = function (id) {
            if ($scope.adding[id]) delete $scope.adding[id];
            else if ($scope.editing[id]) delete $scope.editing[id];
          };

          $scope.saveNote = function (text, id) {
            recipeNotesFactory
              .saveNote($scope.recipeContent.niceName, { id: id, note: text })
              .then(function (steps) {
                $scope.stepNotes = steps;
                $scope.cancelNote(id);
              });
          };

          $scope.deleteNote = function (id) {
            recipeNotesFactory
              .deleteNote($scope.recipeContent.niceName, id)
              .then(function (steps) {
                $scope.stepNotes = steps;
              });
          };

          var iAmFollowing = [];

          toUnregister.push(
            $scope.$watch('currentUser.niceName', function () {
              if ($scope.currentUser) {
                console.log('ROLE', $scope.currentUser.role);

                followersService
                  .getList($scope.currentUser.niceName)
                  .then(function (data) {
                    iAmFollowing = data.following;
                  });

                if (!$scope.currentUser.role.includes('admin')) {
                  $scope.allowedCompats = appConfig.allowedCompats || ['S1'];
                } else {
                  $scope.allowedCompats = appConfig.allowedCompatsAdmin || [
                    'S1',
                    'S2',
                  ];
                }
              }
            })
          );

          $scope.checkFollowers = function (user) {
            return iAmFollowing.find(function (u) {
              return u.niceName == user;
            })
              ? true
              : false;
          };

          $scope.addFollowing = function (currentUser, niceName) {
            followersService
              .addFollowing(currentUser, niceName)
              .then(function (data) {
                followersService
                  .getList($scope.currentUser.niceName)
                  .then(function (data) {
                    iAmFollowing = data.following;
                  });
              });
          };

          $scope.removeFollowing = function (currentUser, niceName) {
            followersService
              .removeFollowing(currentUser, niceName)
              .then(function (data) {
                followersService
                  .getList($scope.currentUser.niceName)
                  .then(function (data) {
                    iAmFollowing = data.following;
                  });
              });
          };

          $scope.nutriErrorsFeed = function () {
            if (
              !$scope.recipeContent.status ||
              !$scope.recipeContent.nutritional ||
              ($scope.recipeContent.status &&
                !$scope.recipeContent.status.nutritional)
            ) {
              $scope.nutriErrors = [
                $rootScope.translations.single_recipe.errors_nutri.no_nutri,
              ];
            } else {
              nutritionalFactory
                .getErrors($scope.recipeContent.niceName)
                .then(function (errors) {
                  if (errors.length) {
                    $scope.nutriErrors = errors
                      .map(function (error) {
                        return $filter('translateVariables')(
                          $rootScope.translations.single_recipe.errors_nutri[
                          error.type
                          ],
                          error.data
                        );
                      })
                      .filter(function (humanError) {
                        return humanError;
                      });
                  } else {
                    $scope.nutriOK = true;
                  }
                });
            }
          };

          $scope.clearNutriErrors = function () {
            if ($scope.nutriErrors) $scope.nutriErrors.length = 0;

            $scope.nutriOK = false;
          };
        }
        init();

        $timeout(function () {
          toUnregister.push(
            $scope.$watch(
              'currentUser.niceName',
              function (newUserNiceName, oldUserNiceName) {
                if (newUserNiceName && $scope.recipeContent) {
                  var ratingPos = $scope.recipeContent.ratings.findIndex(
                    function (rating) {
                      return rating.niceName === $scope.currentUser.niceName;
                    }
                  );

                  if (ratingPos !== -1) {
                    $scope.recipeContent.rating_by_user_position = ratingPos;
                    $scope.recipeContent.rating_by_user =
                      $scope.recipeContent.ratings[ratingPos];
                  }
                } else {
                  if (
                    $scope.recipeContent &&
                    $scope.recipeContent.rating_by_user
                  ) {
                    $scope.recipeContent.rating_by_user = undefined;
                    $scope.recipeContent.rating_by_user_position = undefined;
                  }
                }
              }
            )
          );
        });

        toUnregister.push(
          $rootScope.$on(
            'recipe-subdoc-expand-image',
            function (event, childId, title, subtitle) {
              $scope.amplifyImage(childId, title, subtitle);
            }
          )
        );

        toUnregister.push(
          $rootScope.$on('expanded-image-closing', function () {
            $scope.amplifiedImage = null;
          })
        );

        toUnregister.push(
          $rootScope.$on(
            'recipe-subdoc-updated',
            function (event, position, type, mode) {
              $scope.recipeContent.rating_by_user = undefined;
              $scope.recipeContent.rating_by_user_position = undefined;

              $scope
                .fetchRecipe($filter('urlEncode')($routeParams.recipeNiceName))
                .then(
                  function () {
                    var scrollTarget;

                    if (mode === 'outter') {
                      scrollTarget = $scope.recipeContent.ratings.length
                        ? '#box-' + type + 's .last-title'
                        : '#box-' + type + 's';
                    } else {
                      var targetField = type + 's';
                      scrollTarget =
                        '#' +
                        type +
                        '-' +
                        position.toString().replace('.', '_');

                      if (mode === 'reply') {
                        var parent = parseInt(
                          position.toString().split('.')[0]
                        );
                        var subField =
                          type === 'rating' ? 'replies' : targetField;

                        scrollTarget =
                          '#' +
                          type +
                          '-' +
                          parent +
                          '_' +
                          ($scope.recipeContent[targetField][parent][subField]
                            .length -
                            1);
                      }
                    }

                    $timeout(
                      function () {
                        $('html, body').animate(
                          {
                            scrollTop:
                              $(scrollTarget).offset().top -
                              angular.element('#header').height(),
                          },
                          'slow'
                        );
                      },
                      100,
                      false
                    );
                  },
                  function (error) {
                    $log.error(
                      'RecipeController > fetchRecipe > ERROR: ',
                      error
                    );
                  }
                );
            }
          )
        );

        $scope.$on('$destroy', function () {
          toUnregister.forEach(function (unregister) {
            unregister();
          });
        });
      },
    ]) // end RecipeController
    .directive('recipeNote', function () {
      var _directive = {};
      _directive.scope = {
        text: '=',
        id: '=',
        translations: '=',
        save: '&',
        cancel: '&',
      };
      _directive.link = function (scope, element, attr) { };
      _directive.templateUrl = 'template/recipe/recipeNote.html';
      _directive.controller = [
        '$scope',
        function recipeNotesController($scope) {
          $scope.noteText = $scope.text;

          $scope.changeNote = function (text) {
            $scope.noteText = text;
          };

          $scope.saveNote = function () {
            $scope.save()($scope.noteText, $scope.id);
          };

          $scope.cancelNote = function () {
            $scope.cancel()($scope.id);
          };
        },
      ];

      return _directive;
    })
    .controller('AsideController', [
      '$scope',
      '$rootScope',
      '$location',
      '$log',
      'recipesFactory',
      '$timeout',
      function ($scope, $rootScope, $location, $log, recipesFactory, $timeout) {
        $scope.$on('UPDATED_RECIPE_DATA', function (event, recipeInfo) {
          var totalSuggestions = 0;
          if (
            $scope.recipeContent.seo &&
            $scope.recipeContent.seo.suggestions != null
          ) {
            $scope.randomRecipes = $scope.recipeContent.seo.suggestions;
            totalSuggestions = $scope.recipeContent.seo.suggestions.length;
          }
          if (totalSuggestions < 4) {
            getSuggestions(
              recipeInfo.categoryNiceName,
              recipeInfo.niceName,
              4 - totalSuggestions
            );
          }
        });

        function getSuggestions(catToSearchIn, recipeToExlude, numResults) {
          recipesFactory
            .getRandomRecipes(catToSearchIn, recipeToExlude, numResults)
            .success(function (responseBundle) {
              if (
                $scope.recipeContent.seo &&
                $scope.recipeContent.seo.suggestions != null
              ) {
                $scope.randomRecipes = $scope.randomRecipes.concat(
                  responseBundle
                );
              } else $scope.randomRecipes = responseBundle;
            })
            .error(function (error) {
              $log.error('AsideController > getSuggestions > ERROR1: ', error);
            });
        }
      },
    ]);
})();
